body {
  background-color: #000 !important;
  /*background-image: url(/tunnista_bg.png);*/
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0;
  font-family: "Helvetica Neue", 
    Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.SocialMediaShareButton {
  display: inline;
  float: left;
  margin: 5px;
}
@media screen and (min-width: 1000px) {
    
}